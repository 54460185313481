<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  autofocus
                  :label="$t('admin.productRecommendationDialog.mrgLabel')"
                  v-model="item.mrg"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('admin.productRecommendationDialog.columnNameLabel')"
                  v-model="item.columnName"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('admin.productRecommendationDialog.productTypeLabel')"
                  v-model="item.productType"
                  :items="productTypes"
                  item-text="productTypeName"
                  item-value="productTypeName"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.productRecommendationDialog.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  @click="cancel()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.productRecommendationDialog.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="save(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProductRecommendationDialog",

  props: {
    productTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemInputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("admin.productRecommendationDialog.inputRules"),
      ],
    };
  },

  computed: {
    itemValid() {
      if (!this.item) {
        return false;
      }

      let valid =
        this.item != null &&
        this.item.productType != undefined &&
        this.item.productType != ""

      return valid;
    },
    saveDisabled() {
      if (!this.itemValid) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    save() {
      this.$emit("save", this.item);
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>
