<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="parameters"
    :search="search"
    sort-by="parameterName"
    :loading="loading"
    :loading-text="$t('admin.engineParameters.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.engineParameters.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.engineParameters.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="520px"
        >
          <EngineParameterDialog
            :item="editedItem"
            @cancel="cancel"
            @save="save"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.engineParameters.noParametersFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import EngineParameterDialog from "@/components/admin/EngineParameter/EngineParameterDialog";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "EngineParameters",

  components: {
    EngineParameterDialog,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("admin.engineParameters.parameterNameLabel"),
          value: "parameterName",
        },
        {
          text: this.$t("admin.engineParameters.parameterValueLabel"),
          value: "parameterValue",
        },
        {
          text: this.$t("admin.engineParameters.parameterDescriptionLabel"),
          value: "parameterDescription",
        },
        {
          text: this.$t("admin.engineParameters.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        parameterID: 0,
        parameterName: "",
        parameterValue: 0,
        parameterDescription: "",
      },
      defaultItem: {
        machineID: 0,
        parameterID: 0,
        parameterName: "",
        parameterValue: 0,
        parameterDescription: "",
      },
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["parameters"]),
  },

  methods: {
    getParameters() {
      this.loading = true;

      this.$store.dispatch("getParameters").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.parameters.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(item) {
      this.dialog = false;
      this.loading = true;

      this.$store.dispatch("updateParameter", item).then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });

          this.getParameters();
        },
        (error) => {
          this.loading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
  },

  mounted() {
    this.getParameters();
  },
};
</script>
