<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="productRecommendations"
    :search="search"
    :loading="loading"
    :loading-text="$t('admin.productRecommendations.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.productRecommendations.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.productRecommendations.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="450px"
        >
          <ProductRecommendationDialog
            :productTypes="productTypesList"
            :item="editedItem"
            @cancel="cancel"
            @save="save"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.productRecommendations.noProductRecommendationsFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import ProductRecommendationDialog from "@/components/admin/EngineParameter/ProductRecommendationDialog";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "ProductRecommendations",

  components: {
    ProductRecommendationDialog,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("admin.productRecommendations.mrgLabel"),
          value: "mrg",
        },
        {
          text: this.$t("admin.productRecommendations.columnNameLabel"),
          value: "columnName",
        },
        {
          text: this.$t("admin.productRecommendations.productTypeLabel"),
          value: "productType",
        },
        {
          text: this.$t("admin.productRecommendations.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        productRecommendationID: 0,
        mrg: 0,
        columnIndex: 0,
        columnName: "",
        productType: "",
      },
      defaultItem: {
        productRecommendationID: 0,
        mrg: 0,
        columnIndex: 0,
        columnName: "",
        productType: "",
      },
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["productRecommendations","productTypes"]),
    productTypesList() {
      const list = [...this.productTypes];
      list.push(
        {
          orderInList: null,
          productTypeDescription: "NR",
          productTypeID: 0,
          productTypeName: "NR"
        }
      );
      
      return list;
    }
  },

  methods: {
    getProductRecommendations() {
      this.loading = true;

      this.$store.dispatch("getProductRecommendations").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    getProductTypes() {
      this.loading = true;

      this.$store.dispatch("getProductTypes").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.productRecommendations.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(item) {
      this.dialog = false;
      this.loading = true;

      this.$store.dispatch("updateProductRecommendation", item).then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });

          this.getProductRecommendations();
        },
        (error) => {
          this.loading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
  },

  mounted() {
    this.getProductRecommendations();
    this.getProductTypes();
  },
};
</script>
