<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="productSelections"
    :search="search"
    sort-by="applicationScenario"
    :loading="loading"
    :loading-text="$t('admin.productSelections.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.productSelections.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.productSelections.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="520px"
        >
          <ProductSelectionDialog
            :item="editedItem"
            @cancel="cancel"
            @save="save"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.productSelections.noProductSelectionsFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import ProductSelectionDialog from "@/components/admin/EngineParameter/ProductSelectionDialog";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "ProductSelection",

  components: {
    ProductSelectionDialog,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("admin.productSelections.applicationScenarioLabel"),
          value: "applicationScenario",
        },
        {
          text: this.$t("admin.productSelections.goodLabel"),
          value: "good",
        },
        {
          text: this.$t("admin.productSelections.betterLabel"),
          value: "better",
        },
        {
          text: this.$t("admin.productSelections.bestLabel"),
          value: "best",
        },
        {
          text: this.$t("admin.productSelections.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        productSelectionID: 0,
        good: 0,
        better: 0,
        best: 0,
      },
      defaultItem: {
        productSelectionID: 0,
        good: 0,
        better: 0,
        best: 0,
      },
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["productSelections"]),
  },

  methods: {
    getProductSelections() {
      this.loading = true;

      this.$store.dispatch("getProductSelections").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.productSelections.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(item) {
      this.dialog = false;
      this.loading = true;

      this.$store.dispatch("updateProductSelection", item).then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });

          this.getProductSelections();
        },
        (error) => {
          this.loading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
  },

  mounted() {
    this.getProductSelections();
  },
};
</script>
