<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col class="py-0">
        <EngineParameters />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ProductSelection />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ProductRecommendation />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EngineParameters from "@/components/admin/EngineParameter/EngineParameters";
import ProductSelection from "@/components/admin/EngineParameter/ProductSelection";
import ProductRecommendation from "@/components/admin/EngineParameter/ProductRecommendation";

export default {
  name: "EngineParametersAdmin",

  components: {
    EngineParameters,
    ProductSelection,
    ProductRecommendation,
  },
};
</script>
